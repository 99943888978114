<template>
    <nav v-if="orderListTotal>0" class="woocommerce-pagination navigation">
        <ul v-if="ordersListLoading" class="page-numbers">
        </ul>
        <ul v-else-if="ordersQty < 1" class="page-numbers">
        </ul>
        <ul v-else class="page-numbers">
            <li v-if="currentPage != 1"><a class="next page-numbers" href="#" @click="previosPage"><i class="fa fa-long-arrow-left"></i></a></li>
            <li v-if="currentPage > 3">
                <router-link class="page-numbers" :to="{ name: 'usersOrderList', query: { page: 1} }">1</router-link>
            </li>
            <li v-if="currentPage > 3">
                <span class="page-numbers">...</span>
            </li>
            <li v-for="item in orderAvailPagesBeforeCurrent" v-bind:key="item.id">
                <router-link class="page-numbers" :to="{ name: 'usersOrderList', query: { page: item} }">{{ item }}</router-link>
            </li>
            <li>
                <span class="page-numbers current">{{ currentPage }}</span>
            </li>
            <li v-for="item in orderAvailPagesAfterCurrent" v-bind:key="item.id">
                <router-link class="page-numbers" :to="{ name: 'usersOrderList', query:  { page: item} }">{{ item }}</router-link>
            </li>
            <li v-if="(pageQty-currentPage) > 1">
                <span class="page-numbers">...</span>
            </li>
            <li v-if="(pageQty-currentPage) > 0">
                <router-link class="page-numbers" :to="{ name: 'usersOrderList', query: { page: pageQty} }">{{ pageQty }}</router-link>
            </li>
            <li v-if="currentPage != pageQty"><a class="next page-numbers" href="#" @click="nextPage"><i class="fa fa-long-arrow-right"></i></a></li>
        </ul>
    </nav>
    <div v-else class="col-sm-12">
        <div class="text-center">
            <h2 class="title">В истории нет заказов</h2>
        </div>
        <div class="text-center padding-top-30">
            <router-link class="button primary" :to="{ name: 'catalog', query: {page:1} }">За покупками</router-link>
        </div>
    </div>
</template>

<script>
import { useCookies } from "vue3-cookies"
export default {
    name: "OrderListPaginationComponent",
    setup() {
        const { cookies } = useCookies()
        return { cookies }
    },
    computed: {
        ordersListLoading () {
            return this.$store.getters.ordersListLoading
        },
        ordersQty () {
            return this.$store.getters.orderListQty
        },
        pageQty () {
            return this.$store.getters.pageQty
        },
        currentPage () {
            return this.$store.getters.currentPage
        },
        orderAvailPagesBeforeCurrent () {
            return this.$store.getters.orderAvailPagesBeforeCurrent
        },
        orderAvailPagesAfterCurrent () {
            return this.$store.getters.orderAvailPagesAfterCurrent
        },
        orderListTotal () {
            return this.$store.getters.orderListTotal
        }
    },
    methods: {
        loadOrdersList (page) {
            let authToken = this.cookies.get("sidrograd_token") 
            if (authToken) {
                this.$store.dispatch('loadOrdersList', { authToken: authToken, page: page })
            } else if (this.userToken) {
                this.$store.dispatch('loadOrdersList', { authToken: this.userToken, page: page })
            }
        },
        setCurrentPage (page_num) {
            if (!this.$route.query.page == '') {
                this.$store.dispatch('setCurrentPage', page_num)
            } else {
                this.$store.dispatch('setCurrentPage', 1)
            }
        },
        nextPage: function(el) {
            el.preventDefault()
            if (!this.ordersListLoading) {
                if (!(this.currentPage == this.pageQty)) {
                    this.setCurrentPage(this.currentPage + 1)
                    this.loadOrdersList(this.currentPage)
                    this.$router.push({ name: 'usersOrderList', query: { page:this.currentPage} })
                }    
            }
        },
        previosPage: function(el) {
            el.preventDefault()
            if (!this.ordersListLoading) {
                if (this.currentPage > 1) {
                    this.setCurrentPage(this.currentPage - 1)
                    this.loadOrdersList(this.currentPage)
                    this.$router.push({ name: 'usersOrderList', query: { page:this.currentPage } })
                }
            }    
        }
    },
    watch: {
        '$route.query': {
            immediate: true,
            handler() {
                document.title = 'Список заказов'
                if (this.cookies.get("sidrograd_token")) {
                    if (!this.$route.query.page == '') {
                        this.setCurrentPage(parseInt(this.$route.query.page))
                    } else {
                        this.setCurrentPage(1)
                    }
                    this.loadOrdersList(this.currentPage)
                }
            }
        },
    }
}
</script>