<template>
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-3">
                    <div class="widget widget_text">
                        <h3 class="widget-title">О компании</h3>
                        <div class="textwidget">
                            Торговый дом «Сидроград» - компания, которая занимается продажей сидра, пива, а также безалкогольных напитков.
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-3">
                    <div class="widget">
                        <h3 class="widget-title"></h3>
                        <ul>
                            <li><label>ИНН: <a>3123454345</a></label></li>
                            <li><label>ОГРН <a>1193123013203</a></label></li>
                            <li><label>Адрес: <a>г. Белгород, ул. Чичерина 50Б</a></label></li>
                            <li><label>Тел.: <a href="tel:+78002002113">8 (800) 200-21-13</a></label></li>
                            <li><label>Email: <a href="mailto:cidercity@yandex.ru">cidercity@yandex.ru</a></label></li>
                        </ul>
                    </div>
                </div>
                <div class="google-map col-sm-12 col-md-6">
                    <div id="canvas-for-google-map" style="height:80%; width:100%;max-width:100%;">
                        <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ad090b1e95ed58e2d8863f0c71cbed5bbfdf142d6598c8aa57bb9009e0e4f324a&amp;source=constructor" width="100%" height="300" frameborder="0"></iframe>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-12 justify-content-center" >
                            <div class="coppyright">© {{ creationYear }} {{ currentYear }} ООО "ТД Сидроград". Developed by <a target="_blank" href="https://annasoft.ru/">Annasoft</a></div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>

export default {
    name: "FooterComponent",
    data() {
        return {
            creationYear: (new Date("2024")).getFullYear(),
        }
    },
    computed: {
        currentYear() {
            let date = new Date()
            if (date.getFullYear() == this.creationYear) {
                return ""
            }
            return " - " + date.getFullYear()
        }
    }  
}
</script>

<style scoped>

</style>